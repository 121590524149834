/* blue #219bc3
/* light blue  #b2d3e1
/* light pink  #f2cfda
/* dark pink #c66aab
/* purple  #68417f */

* {
  box-sizing: border-box;
}

body {
  background-color: #f2cfda;
  margin: 0;
  font-family: sans-serif;
}

main {
  padding: 1rem;
  max-width: 1200px;
}

h1 {
  margin: 0 0 2rem 0;
}

p {
  margin: 0 0 2rem 0;
}

.input-container {
  display: flex;
}

.url-input {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06);
  color: #333;
  flex: auto;
  font-size: 1rem;
  margin-right: 1rem;
  padding: 1rem;
}

.get-video-button {
  background-color: #b2d3e1;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06);
  color: #333;
  flex: 0 0 auto;
  font-size: 1rem;
  padding: 1rem 2rem;
}

.get-video-button:hover {

  background-color: #219bc3;

  cursor: pointer;
}

.get-video-button:active {
  position: relative;
  top: 3px;
}